
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { Component, Vue, Inject, Ref, Prop } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import NuclearUnitCreate from "@/components/Core/Create/Localisation/NuclearUnitCreate.vue";

import { IPowerPlantService } from "@/services";

@Component({
  components: {
    NuclearUnitCreate,
  },
})
export default class PowerPlantCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Ref("form")
  form!: any;

  @Prop({ required: true })
  forbiddenNames!: string[];

  @Prop({ required: true })
  forbiddenTrigrams!: string[];

  get powerPlantService() {
    return this.container.resolve<IPowerPlantService>(S.POWER_PLANT_SERVICE);
  }

  get numbers() {
    return _.chain(this.nuclearUnits)
      .map((n) => n.number)
      .filter((n) => n != null)
      .value();
  }

  loading: boolean = false;
  valid: boolean = true;
  name: string | null = null;
  trigram: string | null = null;
  nuclearUnits: { number: number | null; generationId: string | null }[] = [
    { number: null, generationId: null },
  ];

  async submit() {
    try {
      this.loading = true;
      if (this.form.validate()) {
        const nuclearUnitToCreate = _.map(this.nuclearUnits, (n) => {
          return {
            number: n.number!,
            generationId: n.generationId!,
          };
        });

        const powerPlantCreated = await this.powerPlantService.create({
          name: this.name!,
          trigram: this.trigram!,
          nuclearUnits: nuclearUnitToCreate,
        });
        this.$emit("create", powerPlantCreated);
      }
    } finally {
      this.loading = false;
    }
  }

  addNuclearUnit() {
    this.nuclearUnits.push({ number: null, generationId: null });
  }

  deleteNuclearUnit(index: number) {
    this.nuclearUnits.splice(index, 1);
  }

  updateNuclearUnit(value: {
    index: number;
    number: number | null;
    generationId: string | null;
  }) {
    this.nuclearUnits.splice(value.index, 1, {
      number: value.number,
      generationId: value.generationId,
    });
  }

  isNameAllowed(name: string) {
    return (
      _.findIndex(
        this.forbiddenNames,
        (s) => s.toUpperCase() == name.toUpperCase()
      ) != -1
    );
  }

  isTrigramAllowed(trigram: string) {
    return (
      _.findIndex(
        this.forbiddenTrigrams,
        (s) => s.toUpperCase() == trigram.toUpperCase()
      ) != -1
    );
  }
}
