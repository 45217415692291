var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"align":"end"}},[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.value.number,"type":"number","label":"Numéro de la tranche","prefix":"T","rules":[
        (v) => !!v || 'Ce champ est requis pour ajouter une nouvelle tranche',
        (v) => (!!v && _vm.isInteger(v)) || 'Saisir un nombre entier',
        (v) =>
          (!!v && _vm.isInteger(v) && v > 0) || 'Saisir un nombre entier positif',
        _vm.checkNameRule,
      ]},on:{"input":function($event){_vm.onNumberChange(_vm.toNullableNumber($event))}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',[_vm._v("mdi-factory")])]},proxy:true}])})],1),_c('v-col',[_c('generation-autocomplete',{attrs:{"value":_vm.value.generationId,"rules":[(v) => !!v || 'Ce champ est requis']},on:{"input":function($event){return _vm.onGenerationIdChange($event)}}})],1),_c('v-col',{attrs:{"cols":"1","align-self":"center"}},[_c('v-btn',{attrs:{"icon":"","color":"red darken-4"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }